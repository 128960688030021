import "./src/styles/index.scss"


// PRISMIC CONFIGURATION
const { registerLinkResolver } = require('@prismicio/gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/utils/linkResolver');
 
registerLinkResolver(linkResolver);

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `Deze applicatie is geupdate. ` +
      `Ververs de browser voor de nieuwste versie.`
  )

  if (answer === true) {
    window.location.reload()
  }
}