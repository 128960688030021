exports.linkResolver = function linkResolver(doc) {

  // Route for course
  if (doc.type === 'course') {
    return '/course/' + doc.uid;
  }

  // Homepage route fallback
  return '/';
};
