module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TriMotion Colleges Page","short_name":"TriMotion Colleges","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"static/icons/favicon-32x32.png","icons":[{"src":"static/icons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"static/icons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"static/icons/mstile-150x150.png","sizes":"150x150","type":"image/png"},{"src":"static/icons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/icons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"static/icons/apple-touch-icon.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ee306a7d514a6fb8629525a8d044d832"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"trimotion-prismic-gatsby-starter","path":"/preview","previews":true,"pages":[{"type":"Course","match":"/course/:uid","path":"/course-preview","component":"/vercel/workpath0/src/templates/course.js"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
